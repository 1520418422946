<template>
    <div class="card">
        <div class="p-2 title-button">
            <TitleButton
                :showBtn="true"
                :showSettingBtn="false"
                :showAddNew="false"
                :title="'Primary Healthcare Card- Update'"
                @onClickCloseButton="closeButton"
            />
            <div v-if="!loading" class="row">
                <div class="col-md-6 py-1">
                    <p>Patient ID (PIN): {{ $route.query.patientId}}</p>
                    <p>Patient Name: {{ singlePhc.contact_profile.full_name }}</p>			
                    <p class="capitalize">{{ formatAgeAndGender(singlePhc.contact_profile.birthday, singlePhc.contact_profile.gender) }}</p> 			
                    <p>Patient Phone No: {{ singlePhc.mobile_no }}</p>			
                    <p>Patient Address: {{ singlePhc.contact_profile.full_address }}</p>		
                </div>
                <div class="col-md-6 py-1">
                    <p>Date: {{ singlePhc.start_date_time.slice(0, 10) }}</p>	 
                    <p>Consultant: {{ singlePhc.service_resource.name }}</p>	
                    <p>Consultantion type: {{ singlePhc.service_description ? convertToTitleCase(singlePhc.service_description.service_specification) : '' }}</p>
                    <p>Serial no: {{ singlePhc.serial_no }}</p>		
                </div>
            </div>
            <div class="col-md-6 ml-auto px-md-1">
                <v-select
                    placeholder="Name of Sister / Nurse"
                    class="w-60"
                    v-model="formData.nurse_id"
                    :options="nurse"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
        </div>

        <div class="px-3 py-1">
            <div class="col-md-10 row py-1 justify-content-between mx-auto border">
                <div class="row mx-auto">
                    <div class="col-md-6">
                        <label class="form-label" for="height">Height (CM)</label>
                        <vField
                            v-model="formData.height"
                            name="height"
                            type="number"
                            class="form-control"
                        />
                    </div>
                    <div class="col-md-6">
                        <div class="row my-1 my-md-2">
                            <div class="col-6">
                                <vField
                                    :placeholder="formData.height ? convertHeightToFeetAndInches(formData.height).feet : 'Feet'"
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                    readonly
                                />
                            </div>
                            <div class="col-6">
                                <vField
                                    :placeholder="formData.height ? convertHeightToFeetAndInches(formData.height).inches : 'Inches'"
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-auto mb-md-2 mb-1">
                    <div class="col-md-6 mb-1 my-md-0">
                        <label class="form-label" for="weight">Weight (KG)</label>
                        <vField
                            v-model="formData.weight"
                            name="weight"
                            type="number"
                            class="form-control"
                        />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="typeOfBloodPressure">Type of Weight</label>
                        <v-select
                            placeholder="Select type of weights"
                            class="w-100"
                            v-model="formData.type_of_weight"
                            :options="weightTypes"
                            label="name"
                            :reduce="name => name.value"
                        />
                    </div>
                </div>

                <div class="row mx-auto mb-md-2 mb-1">
                    <div class="col-md-6 mb-1 my-md-0">
                        <label class="form-label" for="normalWeight">Normal Weight (KG)</label>
                        <vField
                            v-model.trim="formData.normal_weight"
                            name="normalWeight"
                            type="text"
                            class="form-control"
                        />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="bmi">BMI</label>
                        <vField
                            v-model="bmiCal"
                            placeholder="bmi"
                            name="bmi"
                            type="number"
                            class="form-control"
                            readonly
                        />
                    </div>
                </div>

                <div class="row mx-auto mb-md-2 mb-1">
                    <div class="col-md-6 mb-1 my-md-0">
                        <label class="form-label" for="bloodPressure">Blood Pressure (mmHg)</label>
                        <div class="row">
                            <div class="col-6">
                                <vField
                                    v-model="formData.blood_pressure_uppper"
                                    placeholder="Upper"
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                />
                            </div>
                            <div class="col-6">
                                <vField
                                    v-model="formData.blood_pressure_lower"
                                    placeholder="lower"
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="typeOfBloodPressure">Type of Blood Pressure</label>
                        <v-select
                            placeholder="Select blood pressure types"
                            class="w-100"
                            v-model="formData.blood_pressure_type"
                            :options="bloodPressureTypes"
                            label="name"
                            :reduce="name => name.value"
                        />
                    </div>
                </div>

                <div class="row mx-auto mb-md-2 mb-1">
                    <div class="col-md-6 mb-1 my-md-0">
                        <label class="form-label" for="oxygenLevel">Oxygez level (%)</label>
                        <vField
                            v-model="formData.oxygen_level"
                            name="normalWeight"
                            type="number"
                            class="form-control"
                        />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="pulse">Pulse (b/Min)</label>
                        <vField
                            v-model="formData.pulse"
                            name="pulse"
                            type="text"
                            class="form-control"
                        />
                    </div>
                </div>
                
                <div class="row mx-auto mb-md-2 mb-1">
                    <div class="col-md-6 mb-1 my-md-0">
                        <label class="form-label" for="bloodGroup">Blood Group</label>
                        <v-select
                            v-model="formData.blood_group"
                            placeholder="Select Blood Group"
                            :options="bloodGroups"
                            label="name"
                            :reduce="name => name.value"
                        />
                    </div>
                    <div class="col-md-6">
                        <div class="w-100 py-2">
                            <button @click="submit" class="btn btn-primary w-100">Save</button>
                        </div>
                    </div>
                </div>
                
            </div> 
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import { useRoute, useRouter } from "vue-router";
    import { onMounted, ref, computed, inject } from 'vue';
    import handleHospitalPHC from "@/services/modules/hospital/phc";
    import Loader from "@/components/atom/LoaderComponent.vue";
    import handleHospital from "@/services/modules/hospital";
    import handleContact from '@/services/modules/contact'
    import { convertToTitleCase } from '@/services/utils/global';
    
    const { fetchSinglePHC, fetchBloodPressure, storeOrUpdatePHC } = handleHospitalPHC();
    const { dateOfBarthConvertToAge, convertHeightToFeetAndInches, bmiCalculator } = handleHospital();
    const { fetchContactProfiles } = handleContact();
    import handleCompany from "@/services/modules/company";
    import phcPdfHelper from "@/services/utils/pdf/phcPdfHelper";

    const showError = inject('showError');

    const singlePhc = ref({})
    const loading = ref(true);
    const companyInfo = ref({});

    const $route = useRoute();
    const $router = useRouter();

    const { fetchCompanyInfo } = handleCompany();
    const { generatePdf } = phcPdfHelper();

    const companyId = $route.params.companyId;
    const bloodGroups = ref([]);
    const bloodPressureTypes = ref([]);
    const weightTypes = ref([]);
    const nurse = ref([]);

    const formData = ref({
        company_id:  companyId,
        service_general_id: null,
        nurse_id: null,
        height: 0,
        weight: 0,
        normal_weight: '',
        bmi: 0,
        blood_pressure_uppper: 0,
        blood_pressure_lower: 0,
        blood_pressure_type: null,
        oxygen_level: 0,
        pulse: 0,
        blood_group: null,
        type_of_weight: 'normal'
    })

    onMounted( async () => {
        loading.value = true
        const singlePhcQuery = `/${$route.query.id}?company_id=${companyId}`
        const query = `?company_id=${companyId}`

        await Promise.all([
            fetchSinglePHC(singlePhcQuery).then((res) => {
                if(res.status){
                    singlePhc.value = res.data
                    formData.value.nurse_id = res.data.nurse_id ? res.data.nurse_id : null;
                    if(res.data.phc_general){
                        formData.value = res.data.phc_general
                    }
                }
            }),
            fetchBloodPressure(query).then((res) => {
                if(res.status){
                    bloodGroups.value = res.data.bloodGroups;
                    bloodPressureTypes.value = res.data.bloodPressureTypes;
                    weightTypes.value = res.data.weightTypes;
                }
            }),
            fetchContactProfiles(query + `&type=nurse`).then((res) => {
                if(res.status) {
                    nurse.value = res.data
                }
            })
        ]).catch((e) => {
            console.log('Something went wrong');
        }).finally(() => {
            loading.value = false
        })
    })

    const bmiCal = computed(() => bmiCalculator(formData.value.height, formData.value.weight))

    const formatAgeAndGender = (age, gender) => {
        return `${age ? 'Age: ' + calculateAge(age) : ''}` + 
                `${gender ? ' | Sex: ' + gender : '' }`
    }

    const validation = () => {
        if(!formData.value.height){
            showError('Please select height')
            return false
        }
        if(!formData.value.weight){
            showError('Please select weight')
            return false
        }
        return true;
    }

    const submit = () => {
        if(!validation()){
            return
        }

        formData.value.bmi = bmiCal.value ? bmiCal.value : 0;
        formData.value.service_general_id = singlePhc.value.id;
        formData.value.company_id = companyId;
        
        storeOrUpdatePHC(formData.value).then((res) => {
            if(res.status){
                goToPrint()
                $router.push({
                    name: 'prescription-psc',
                    params: $route.params,
                    query: $route.query
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const goToPrint = () => {
        const singlePhcQuery = `/${$route.query.id}?company_id=${companyId}`;

        Promise.all([
            fetchCompanyInfo(companyId).then((res) => {
                if(res.status) {
                    companyInfo.value = res.data;
                }
            }),
            fetchSinglePHC(singlePhcQuery).then((res) => {
                if(res.status){
                    singlePhc.value = res.data
                }
            })
        ]).catch(e => console.log('Something went wrong'))
        .finally(() => generatePdf(companyInfo.value, singlePhc.value, $route.query.patientId))
    }

    const calculateAge = (birthday) => {
        const {year, month, day} = dateOfBarthConvertToAge(birthday)
        return `${year}Y, ${month}M, ${day}D`
    }

    const closeButton = () => {
        $router.back();
    }
</script>

<style scoped>
.w-60{
    width: 60%;
}
</style>